import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import ContactFormImpl from '@mangoart/gatsby-ui/components/ezagrar/ContactForm';
import InnerForm from './InnerFormHersteller';

import * as styles from './ContactForm.module.css';
import { useTranslation } from 'gatsby-plugin-react-i18next';

ContactForm.propTypes = {
  cols: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};
ContactForm.defaultProps = {};

function ContactForm({ cols, width, contactPhone }) {
  const { t, i18n } = useTranslation('ContactForm');

  return (
    <div className={clsx(styles.ContactForm, styles.box, styles[`box${width}Of${cols}`])}>
      <div>
        <h2>{t('title_manufacturer')}</h2>
        <h3>{t('teaser_manufacturer')}</h3>
        <span className={clsx('phoneContact', styles.phoneContact)}>
          <span className="icon-phone"></span> {contactPhone || '0732/341144-0'}
        </span>
      </div>
      <ContactFormImpl form={InnerForm} mailid={'ezagrar'} t={t} />
    </div>
  );
}

export default ContactForm;
