import React from 'react';

import * as styles from './Image.module.css';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export default function Image({ node }) {
  const { name, image } = node;

  return (
    <div className={styles.image}>
      <GatsbyImage alt={name} image={getImage(image)} />
    </div>
  );
}
