import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import * as styles from './ProductEntry.module.css';

export default function ProductEntry({ product }) {
  if (!product) {
    return null;
  }
  const { name, image } = product;

  const valid = image;
  if (valid) {
    return (
      <div className={styles.content}>
        <div className={styles.top}>
          <GatsbyImage image={getImage(image)} alt={name} style={{ height: 170 }} imgStyle={{ height: 170 }} />
        </div>
        <div className={styles.actionContainer}>
          <div className={styles.action}>{name}</div>
        </div>
      </div>
    );
  }
  return null;
}
