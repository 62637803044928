import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { Helmet } from 'react-helmet';
import Image from '../components/Hersteller/Image';
import Description from '../components/Hersteller/Description';
import ContactForm from '../components/HerstellerNeu/ContactForm';
import ProductEntry from '../components/Hersteller/ProductEntry';
import { useTranslation } from 'gatsby-plugin-react-i18next';

// import HerstellerDetail from 'Components/HerstellerDetail';

HerstellerTemplate.propTypes = {};

const countProducts = (products) => {
  let validProducts = 0;
  products.forEach(function (product) {
    if (product) {
      const { image, name } = product;
      if (image && name) {
        validProducts++;
      }
    }
  });
  return validProducts;
};

const renderProduct = (product) => <ProductEntry product={product} />;

function HerstellerTemplate({ data }) {
  const { item } = data;
  const { name, description, products } = item;
  const { t } = useTranslation('ContactForm');

  const filteredProducts = Array.isArray(products)
    ? products.filter((product) => {
        return product != null;
      })
    : [];

  const validProducts = countProducts(filteredProducts);
  const contactFormWidth = validProducts % 4 === 0 ? 4 : 4 - (filteredProducts.length % 4);

  return (
    <DefaultLayout>
      <div>
        <Helmet>
          <title>{name}</title>
          <meta name="description" content={description} />
        </Helmet>
        <div className={'widewrap '}>
          <Image node={item} />
        </div>
        <div className={'wrap'}>
          <Description node={item} />
          <div style={{ margin: '0 -3px -6px -3px' }}>
            {filteredProducts ? filteredProducts.map(renderProduct) : null}
            <ContactForm node={item} width={contactFormWidth} cols={4} t={t} contactPhone={'0732/347266-0'} />
            {/* <ContactForm cols={4} width={contactFormWidth} contactPhone={'0732/347266-0'} /> */}
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}

export default HerstellerTemplate;

export const query = graphql`
  query ($id: String, $language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    item: cockpitHersteller(id: { eq: $id }) {
      id
      name
      country
      description
      site
      category
      image {
        publicURL
        childImageSharp {
          gatsbyImageData(width: 2000, height: 800, layout: CONSTRAINED, placeholder: NONE)
        }
      }
      products {
        name
        image {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 420, aspectRatio: 1.6, placeholder: NONE)
          }
        }
      }
      logopng {
        publicURL
        childImageSharp {
          gatsbyImageData(width: 400, placeholder: NONE)
        }
      }
      logojpg {
        publicURL
        childImageSharp {
          gatsbyImageData(width: 400, placeholder: NONE)
        }
      }
    }
  }
`;
