import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import * as styles from './InnerFormHersteller.module.css';
import { Link } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

InnerForm.propTypes = {};
InnerForm.defaultProps = {};

const submitFeedback = (status, t) => {
  if (status === 'submitted') {
    return <div className={clsx(styles.formControl, styles.formFeedback)}>{t('success')}</div>;
  }
  if (status === 'error') {
    return <div className={clsx(styles.formControl, styles.formFeedback, styles.error)}>{t('contact_form_error')}</div>;
  }
  return null;
};

function InnerForm(props) {
  const { values, touched, errors, status, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, handleReset } =
    props;

  const { t } = useTranslation('ContactForm');

  return (
    <form onSubmit={handleSubmit} className={clsx(styles.ContactForm, styles[values.layout || 'small'])}>
      <div className={styles.column}>
        <div className={styles.formControl}>
          <input
            id="email"
            placeholder={t('email')}
            aria-label={t('email')}
            aria-required="true"
            autoComplete="email"
            type="text"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            className={errors.email && touched.email ? 'text-input error' : 'text-input'}
          />
          {errors.email && touched.email && <div className="input-feedback">{errors.email}</div>}
        </div>
        {values.layout === 'wide' && submitFeedback(status, t)}
      </div>

      <div className={styles.column}>
        <div className={styles.formControl}>
          <textarea
            id="message"
            placeholder={t('message')}
            aria-label={t('message')}
            aria-required="true"
            value={values.message}
            onChange={handleChange}
            onBlur={handleBlur}
            className={errors.message && touched.message ? 'text-input error' : 'text-input'}
          />
          {errors.message && touched.message && <div className="input-feedback">{errors.message}</div>}
        </div>

        <div id="acceptDatenschutzFormField" className={styles.acceptDatenschutz}>
          <label>
            <input
              id="acceptDatenschutz"
              name="acceptDatenschutz"
              type="checkbox"
              value={'acceptDatenschutz'}
              checked={values.acceptDatenschutz}
              onChange={handleChange}
            />
            <span>
              {t('dataprivacy_prefix')}{' '}
              <Link style={{ background: 'transparent' }} to={t('dataprivacy_link')}>
                {t('dataprivacy')}
              </Link>{' '}
              {t('dataprivacy_postfix')}
            </span>
          </label>
          {errors.acceptDatenschutz && touched.acceptDatenschutz && (
            <div className="input-feedback">{errors.acceptDatenschutz}</div>
          )}
        </div>

        <div className={clsx(styles.formControl)} style={{ marginTop: 0 }}>
          <button type="submit" disabled={isSubmitting}>
            {t('button')}
          </button>
        </div>

        {values.layout !== 'wide' && submitFeedback(status, t)}
      </div>
    </form>
  );
}

export default InnerForm;
