// extracted by mini-css-extract-plugin
export var ContactForm = "ContactForm-module--ContactForm--oIBN7";
export var box = "ContactForm-module--box--BroxO";
export var box1Of1 = "ContactForm-module--box1Of1--9Z3Sx";
export var box1Of2 = "ContactForm-module--box1Of2--uUa+B";
export var box1Of3 = "ContactForm-module--box1Of3--G+N16";
export var box1Of4 = "ContactForm-module--box1Of4--GdMqO";
export var box2Of2 = "ContactForm-module--box2Of2--f-PSO";
export var box2Of3 = "ContactForm-module--box2Of3--E-vbU";
export var box2Of4 = "ContactForm-module--box2Of4--H6uCF";
export var box3Of3 = "ContactForm-module--box3Of3--9WSZu";
export var box3Of4 = "ContactForm-module--box3Of4--RPpKm";
export var box4Of4 = "ContactForm-module--box4Of4--RaBJo";
export var phoneContact = "ContactForm-module--phoneContact--k1cyI";