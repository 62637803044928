import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import * as styles from './ContactForm.module.css';

function encode(data) {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

const Form = (props) => {
  const { layout, form, mailId, t } = props;

  const requiredFieldMessage = t ? t('required_field') : 'Nachricht ist ein Pflichtfeld!';
  const invalidEmailMessage = t ? t('invalid_email') : 'Ungültige E-Mail Adresse!';
  const minWidth20Message = t ? t('minwidth_20') : 'Eine Nachricht muss aus mindestens 20 Zeichen bestehen!';
  const dataPrivacyRequired = t
    ? t('dataprivacy_consent_required')
    : 'Die Datenschutzbestimmungen müssen akzeptiert werden!';

  return (
    <Formik
      initialValues={{
        email: '',
        message: '',
        layout,
        acceptDatenschutz: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email(invalidEmailMessage).required(requiredFieldMessage).trim(),
        message: Yup.string().required(requiredFieldMessage).min(20, minWidth20Message).trim(),
        acceptDatenschutz: Yup.bool().oneOf([true], dataPrivacyRequired),
      })}
      onSubmit={(values, { setSubmitting, setErrors, setStatus, resetForm }) => {
        fetch('https://mailproxy.projects.localhost.at/index.php', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({
            mailId: mailId,
            email: values.email,
            message: values.message,
          }),
        })
          .then(() => {
            setSubmitting(false);
            resetForm({
              email: '',
              message: '',
              layout,
            });
            setStatus('submitted');
          })
          .catch((error) => {
            console.error(error);
            setErrors();
            setSubmitting(false);
            setStatus('error');
          });
      }}
      render={form}
    />
  );
};

export default Form;
