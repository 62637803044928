import React from 'react';

import Button from '../Common/Button';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import * as styles from './Description.module.css';

import countries from 'i18n-iso-countries';
import countriesDE from 'i18n-iso-countries/langs/de.json';
import countriesEN from 'i18n-iso-countries/langs/en.json';

countries.registerLocale(countriesDE);
countries.registerLocale(countriesEN);

function Description({ node }) {
  const { name, site, logopng, logojpg, logosvg, description, country } = node;

  const hasPng = !!logopng;
  const hasJpg = !!logojpg;
  const hasSvg = !!logosvg;

  const logoToUse = hasPng ? logopng.publicURL : hasSvg ? logosvg.publicURL : hasJpg ? logojpg.publicURL : false;

  const { t, i18n } = useTranslation();
  const { language } = i18n;
  return (
    <div className={styles.content}>
      <div className={styles.logo}>{logoToUse ? <img src={logoToUse} /> : null}</div>
      <div className={styles.container}>
        <div className={styles.left}>
          <h2>{name}</h2>
          <div className={styles.flag}>
            <img src={`/img/flags/${country}.png`} />
            <span>{countries.getName(country, language)}</span>
          </div>
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </div>
        <div className={styles.right}>
          {!!site ? (
            <Button to={site} color="blue" icon="arrow-right">
              {t('externalWebsiteLink')}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Description;
